<template>

  <div>
    <section class="selfcare-title d-flex">
      
    </section>

    <section class="p-2 terms">
      <div class="container">
        <p>
          <img style="width: 30px;" src="/img/18-plus.png">
          <br />
          <strong style="color: var(--white);">
            qwerty.com does not condone underage gambling. You must be 18 years of age or older to register or
            play at qwerty.com.
          </strong>
        </p>
        <h1 style="color: var(--yellow);">Terms and Conditions</h1>
        <p>These general Terms and Conditions shall be valid for all betting games offer by qwerty. qwerty is
          a
          trademark registered and owned by qwerty Limited, company registered under Kenyan Laws and hereinafter
          the
          name qwerty shall synonymously be used with qwerty.</p>

        <p>By using our SMS and/or visiting any section of the Website, or by opening an account with qwerty
          through
          the Website, or SMS, you agree to be bound by the Terms and Conditions. qwerty reserves the right to
          change
          these Terms and Conditions at any time and without prior notice and will endeavor to inform you about major
          modifications to the Terms and Conditions. We may need to change the Terms of Use from time to time for a
          number of reasons, including (without limitation) for commercial reasons, to comply with law or regulations,
          to comply with instructions, guidance or recommendations from a regulatory body, or for customer service
          reasons.</p>

        <p>The user is responsible in ensuring that they don’t infringe on any laws in their jurisdiction when opening
          an account and conducting business with qwerty at all times during the subsistence of their subscription/
          participation.</p>

        <p>qwerty will accept no liability from third parties whatsoever, resulting from you providing incorrect or
          false data.</p>

        <p>One customer may open only one account. Should we identify any customer with more than one account, we
          reserve the right to close these accounts. If qwerty detects multiple accounts which have been setup
          deliberately with misleading information, have displayed criminal behavior, or if qwerty concludes that
          the
          account-owner has fraudulent intentions, qwerty shall be entitled to close the accounts and confiscate
          all
          funds, including initial deposit.</p>

        <p>Notwithstanding anything contained in these Terms and Conditions, the Customer's winnings shall be subject to
          the governing regulations in the Republic of Kenya and this shall include but not be limited to applicab taxes
          and levies.</p>

        <h2 style="color: var(--yellow);">Definitions</h2>
        <dl class="definition-list">
          <dt>Bet</dt>
          <dd>The staking of money on the prediction of the outcome of a sporting event offered by us. A bet can include
            one or more predictions. A bet on one outcome is a single. A bet on many different outcomes is a multiple.
          </dd>

          <dt>Odds or Price</dt>
          <dd>The return on the money staked should the prediction recorded in a Bet be correct. A winning price of 2
            would return double to the stake to the customer.
          </dd>

          <dt>Customer</dt>
          <dd>Anyone placing a bet with qwerty or any other services hosted or sponsored by said company.</dd>

          <dt>User</dt>
          <dd>Anyone using this service.</dd>

          <dt>Full time (FT)</dt>
          <dd>The point when the official 90 minutes of a full match has elapsed plus any 'Injury Time' that the referee
            chooses to add and shall NOT include scheduled extra time (two half times of 15 minutes each), penalty
            shoot-outs etc.
          </dd>

          <dt>VOID</dt>
          <dd>That for one reason or another the bet is not acceptable to qwerty. In these cases, this bet is
            settled
            at the price of 1. In the case of a single bet this would mean the stake is returned to the customer.
          </dd>
        </dl>

        <h2 style="color: var(--yellow);">General qwerty Bonus Terms:</h2>
        <ul>
          <li>
            If risk-free bet, arbitrage or matched bets have been made, we will declare a bet or stake partially or
            fully
            voided and the account may be closed.
          </li>
          <li>
            Irregular play at qwerty will be cause for an instant dismissal from this offer.
          </li>
          <li>
            qwerty reserves the right to modify or change the Terms and Conditions for promotions at any time.
          </li>
          <li>
            qwerty reserves the right to cancel the promotion at any time.
          </li>
          <li>
            qwerty reserves the right to disqualify any player for promotions, close the account, and confiscate
            existing funds if there is evidence of fraud.
          </li>
          <li>
            All general qwerty terms and conditions apply.
          </li>
          <li>
            Before any withdrawals are processed, your play will be reviewed for any irregular playing patterns. Should
            qwerty deem that irregular game play has occurred, we reserve the right to withhold any withdrawals
            and/or confiscate all winnings.
          </li>
        </ul>

        <h2 style="color: var(--yellow);">REGISTER, DEPOSIT & GET 100% OF YOUR FIRST DEPOSIT up to KSH. 500</h2>
        <p>Open to ALL New Players!</p>

        <h2 style="color: var(--yellow);">100% WELCOME BONUS up to KSH. 500</h2>
        <p>
          All NEW Players get to enjoy a WELCOME BONUS on your first deposit up to KSH. 500
          Make your first deposit and get 100% bonus up to KSH. 500
          E.g If you deposit 100/= your qwerty account will be credited 100/= and your Bonus
          account will be 100/=. We DOUBLE your First deposit up to KSH. 500
        </p>

        <h2 style="color: var(--yellow);">HOW TO CLAIM 100% WELCOME BONUS</h2>
        <p>
          Are you a NEW Player? Below are the steps on how to claim the 100% Welcome Bonus
          up to KSH. 500
        </p>
        <ul>
          <li>Login to qwerty</li>
          <li>Click “Deposit” and the Deposit page will pop up.</li>
          <li>Enter your deposit amount. Minimum 49/=</li>
        </ul>
        <p>
          Your 1st Deposit amount will be doubled. If you deposit 100/= you receive 100/= EXTRA FREE
          Players have 24 hours to use their bonus after it is credited to their account.
        </p>

        <h2 style="color: var(--yellow);">HOW TO USE 100% WELCOME BONUS</h2>
        <ul>
          <li>Login on qwerty and Select Games with minimum Odds above 1.77</li>
          <li>Play on ANY Market (1X2, GG, NG and MORE)</li>
          <li>Click on the betslip, scroll down and input your stake under the betslip. (You can combine your bonus
            stake and cash stake)</li>
          <li>Proceed to “Place a bet”</li>
        </ul>
        <p>
          You will receive a Pop-Up confirming the bet is successful.
          Kindly note that the Welcome Bonus has a wagering requirement (1x)
          Eligible to NEW qwerty Players.
          Promotion valid for 30 days.
          General qwerty terms and conditions apply.
        </p>

        <h2 style="color: var(--yellow);">DEPOSIT & GET 100% OF YOUR FIRST DEPOSIT OF THE DAY!</h2>
        <p>
          Are you a qwerty Player? Below are the steps on how to claim the 100% First Deposit Bonus
          up to KSH. 250
        </p>
        <ul>
          <li>Login to qwerty</li>
          <li>Click “Deposit” and the Deposit page will pop up.</li>
          <li>Enter your deposit amount. Minimum 49/=</li>
        </ul>
        <p>
          Your 1st Deposit amount will be doubled. If you deposit 100/= you receive 100/= EXTRA FREE
          Players have 24 hours to use their bonus after it is credited to their account.
        </p>

        <h2 style="color: var(--yellow);">HOW TO USE 100% FIRST DEPOSIT BONUS</h2>
        <ul>
          <li>Login on qwerty and Select Games with minimum Odds above 1.77</li>
          <li>Play on ANY Market (1X2, GG, NG and MORE)</li>
          <li>Click on the betslip, scroll down and input your stake under the betslip. (You can combine your bonus
            stake and cash stake)</li>
          <li>Proceed to “Place a bet”</li>
        </ul>
        <p>
          You will receive a Pop-Up confirming the bet is successful.
        </p>
        <p>
          Kindly note that the Welcome Bonus has a wagering requirement (1x)
        </p>
        <p>
          Eligible to NEW qwerty Players.
        </p>
        <p>
          Promotion valid for 30 days.
        </p>
        <p>
          General qwerty terms and conditions apply.
        </p>

        <h2 style="color: var(--yellow);">1. Identity of Customers</h2>
        <p>Your mobile phone number is your identity. The identity of customers is determined by mobile phone number. We
          strongly encourage all customers to sign up for identity programs administered by the mobile phone companies.
          We use mobile phone numbers to uniquely identify customers and their account details. If transactions are made
          via your phone by someone who is not the registered user, the registered user will remain liable for their
          transactions. In addition, we cannot do account transactions for accounts which are not identical to the phone
          number. This means that you cannot, for example, ask to be paid out on a different number other than the
          number you have placed a bet with; also you cannot send money to us and request that this is placed in a
          different account. In addition to your phone number your initial transaction will initiate the creation of a
          virtual account to which all monies added to the cause of betting will be held.</p>
        <p><strong style="color: var(--yellow);">Note:</strong> This is not a standard banking account and it shall not be used to keep money other
          than the use to place bets.</p>

        <h2 style="color: var(--yellow);">2. Age Restriction</h2>
        <p>The Law prohibits under 18 year olds from betting. We may at any time ask you to confirm your age. You
          indemnify us of any damages if you deceive us or misrepresent your age. Underage clients will have their
          accounts closed immediately and any balances forfeit. We may also suspend ANY account for any reason and ask
          you to come with ID to one of our offices or agents to prove age, and identity. Individuals suspecting
          underage gambling are encouraged to report this to us immediately on our customer service number or via email
          to support@bahatibet.waliliana.co.ke</p>

        <h2 style="color: var(--yellow);">3. ACCOUNT SECURITY</h2>
        <p>Customers are the registered user of their mobile number and Account. Customers are responsible for the
          security of their phone numbers, Account details and their mobile money accounts. We strongly recommend that
          customers do not disclose any security sensitive information to any other person. In the event that an
          unauthorized person uses a customer's phone for betting or money transactions to us, the registered user
          remains responsible.</p>
        <p>We may also ask you to identify yourself and collect your winnings at one of our premises if we so
          require.</p>

        <h2 style="color: var(--yellow);">4. PRICING</h2>
        <p>Customers will incur charges when using SMS services. We keep these as low as possible, currently at Ksh.3
          for each SMS you receive from us on any of the participating networks. The minimum bet amount is Kshs. 10 for
          single bets and multibets. Jackpot betting is at a fixed stake of Kshs. 50. The minimum bet amount and fixed
          stake for jackpot may change from time to time and customers shall bear the responsibility to confirm and
          update themselves with the said changes.</p>

        <h2 style="color: var(--yellow);">5. ACCEPTANCE AND VALIDATION OF BETS</h2>
        <p>All football bets will be settled on 90 minutes’ play (also referred to as Full-time and normal time). This
          denotes the period of play which includes time added by the Referee for injuries and other stoppages. This
          does not include scheduled extra time, or Penalty shoot-outs, if played.</p>
        <p>In matches where penalty shoot outs or extra time are due to take place, all bets are settled on 90 minutes
          unless an Outright price is specifically requested and confirmed at the time the bet is placed.</p>
        <p>Where qwerty have not quoted prices on a match, any single bet on that match will be void and treated as
          a
          non-runner in multiple bets.</p>
        <p>If any team starts a 90-minute competitive game with less than 11 players, all bets on that match will be
          made void.</p>

        <h2 style="color: var(--yellow);">6. LATE BETS</h2>
        <p>Bets can only be placed on the outcome of a future event, unless we are offering special 'betting in-running
          prices' for that event. If qwerty mistakenly accepts a bet for an event that has already started then the
          bet
          will be void and your stake returned, except in the following circumstances: - Where bets are taken after the
          start of an event, bets will stand if nothing has happened which would significantly alter the chances of the
          selection winning or losing.</p>
        <p>All bets placed after the result of an event is known will be void win or lose. Internet bets include bets
          placed on any of our remote channels including our www.bahatibet.waliliana.co.ke website and via SMS. In the case of such
          bets, the bet is struck when a bet id or receipt exists in our back end systems or database. We may refuse to
          take bets placed if they are deemed wrong or mistaken.</p>

        <h2 style="color: var(--yellow);">7. BET CANCELLATION</h2>
        <p>You can cancel a bet ten(10) minutes after placing that particular bet. This should be before the kick-off
          time of a match(es) selected in that bet. The maximum number of bets that can be cancelled in a day is
          three(3).</p>
        <p>To cancel a bet on SMS, send CANCEL#BET ID to 23511. You can also cancel a bet by logging into the website,
          www.bahatibet.waliliana.co.ke, 'My Bets' then select the particular Bet ID you want to cancel, click on "Cancel Bet' and
          then confirm.</p>

        <h2 style="color: var(--yellow);">8. ACCOUNT CORRECTIONS</h2>
        <p>qwerty has the right to recover from you any amount overpaid and has your authority to adjust your
          account
          to reflect the true outcome and rectify the mistake. An example of such a mistake might be where a price is
          incorrect, a bet is late (see Acceptance and Validation of Bets), where a maximum payout limit has been
          reached and paid out erroneously or where we enter a result of an event incorrectly.</p>

        <h2 style="color: var(--yellow);">9. DISPUTES</h2>
        <p>If you have a complaint; you can email customer support on support@bahatibet.waliliana.co.ke and if your query or
          complaint is not resolved, you can ask for the matter to be escalated to the Head of Betting Operations. The
          Head of Betting Operations will investigate and contact you back with a resolution within 48 hours. You will
          be given the name and status of the people to whom your query/complaint has been referred. If you remain
          dissatisfied, we may agree with you to refer the matter to an independent third body for arbitration.</p>
        <p>qwerty will at all times apply best efforts to resolve a reported matter promptly. If you have a query
          with
          regard to any transaction, you may also contact qwerty at support@bahatibet.waliliana.co.ke with details of the query.
          qwerty will review any queried or disputed transactions.</p>
        <p>If for some reason you are not satisfied with the resolution of the complaint by the Company, you can
          complain to the Betting Control and Licensing Board ("BCLB"). Kindly note that by contacting the BCLB you are
          confirming that you have not breached any of the qwerty Terms and Conditions as agreed to by you upon
          registration to our service.</p>
        <p>In all other times where a dispute arises, parties shall refer the matter for Arbitration by a single
          arbitrator agreed by the parities where the chosen venue shall be Nairobi. Where there is no agreement each
          party shall appoint an arbitrator and the nominated arbitrators shall appoint a single arbitrator and where
          they are unable to agree the Chairman for the time being of the Kenyan Chapter of Chartered Institute of
          Arbitrators shall appoint one.</p>
        <p>Offensive or rude language, as well as malicious or damaging comments, will not be tolerated while contacting
          our staff or while discussing our products and services in any media, social network or forum. Any
          infringement of this policy will result in a suspension of the Account or in every additional action as may be
          required in order to ensure compliance.</p>

        <h2 style="color: var(--yellow);">10. ERRORS</h2>
        <p>qwerty makes every effort to ensure that we do not make errors in accepting bets. However, if as a
          result of
          human error or system problems a bet is accepted at a price (which includes the odds, handicap provisions, and
          other terms and conditions or details of the bet) that is: materially different from those available in the
          general market at the time the bet was made; or clearly incorrect given the chance of the event occurring at
          the time the bet was made then qwerty will pay winnings at the correct price.</p>
        <p>To establish the correct price qwerty will consider prices available in the general market at the time
          the
          bet was made, including the prices offered by other bookmakers operating in the same market.</p>
        <p>Examples of circumstances where this would apply are: - the price is recorded as 100-1 when the price on
          offer in the general market is 10-1 - the margins for handicap betting have been reversed</p>
        <p>If a bet is accepted in error by qwerty on an event or outcome for which no qwerty prices are
          available,
          the bet will be void and your stake returned.</p>
        <p>A bet accepted on the website or via SMS does not constitute a legally binding contract between the customer
          and us. The prices we offer on all outcomes on display on this website take the form of an advertisement
          rather than a contractual term. If we offer mistaken prices, then we are entitled to decline any bet placed at
          those prices at any time. In such instances we will void the bet and return the stake money.</p>
        <p>Any results or scores displayed on the site for example during betting in play are for guidance purposes
          only.</p>

        <h2 style="color: var(--yellow);">11. FRAUD</h2>
        <p>qwerty will seek criminal and contractual sanctions against any customer involved in fraud, dishonesty
          or
          criminal acts. qwerty will withhold payment (including but not limited to possible payouts and bet
          amount) to
          any customer where any of these are suspected.</p>
        <p>The customer shall indemnify and shall be liable to pay to qwerty, on demand, all costs, charges or
          losses
          sustained or incurred by qwerty (including any direct, indirect or consequential losses, loss of profit
          and
          loss of reputation) arising directly or indirectly from the customer's fraud, dishonesty or criminal act.</p>
        <p><strong style="color: var(--yellow);">Match or Price Rigging:</strong></p>
        <p>qwerty takes sporting and betting integrity very seriously. We work with our Gaming Regulators and
          independent Sports Associations to help ensure that the fairness of gaming and the integrity of sport are
          protected. In the event that the company suspects any event manipulation it withholds the right, in its
          absolute discretion, to:</p>
        <ul>
          <li>suspend the offering of any event or series of events in any of its markets; and</li>
          <li>delay and/or withhold payment on any event or series of events in any of its markets, until the integrity
            of such event or series of events has been confirmed by the relevant sports federation.
          </li>
        </ul>
        <p>Further, in the case of active event manipulation being confirmed as having taken place on any event or
          series of events by the appropriate sport’s governing bodies, the company withholds the right, in its absolute
          discretion, to suspend any bets placed on such events, either by any individual identified as having possessed
          insider betting knowledge or information or by any other individual who in the reasonable opinion of the
          company is connected to, acting in conjunction with or in any way involved with such individual.</p>
        <p>If the company believes that match or price rigging has taken place, it reserves the right to relay the
          respective party's saved details (information pertaining to the reservations or suspicions of the respective
          misdemeanour in question) to sporting bodies, authorities or any other third party, which deals with the
          investigation of such offences.</p>

        <h2 style="color: var(--yellow);">12. PRICE CHANGES</h2>
        <p>All prices are subject to change and may, on occasion, be restricted to certain stake levels. The prices
          offered via our different business channels may vary. For example, our betting shops may offer slightly
          different prices from those available on this website or available by SMS.</p>

        <h2 style="color: var(--yellow);">13. RESULTS</h2>
        <p>In the case of Football and other Sports, bets are settled on the official result published by the relevant
          event managers or result handlers 3 to 24 hours after the match/event is finished.</p>

        <h2 style="color: var(--yellow);">14. VOID BET</h2>
        <p>"Void Bet" means the bet is nil or invalid. This occurs when an event is postponed/cancelled, or when it has
          started but not finished within the period specified in our policy. If a game has been cancelled or postponed
          there is always a 24 hours wait until the match will be set as void. Once the match has been set as void (with
          odd 1.00) the rest of the winning ticket will then be paid out. If a selection in a single bet is made void
          the stake will be returned. Void selections in multiple bets will be treated as non-runners and the stake will
          run onto the remaining selections in the bet.</p>

        <h2 style="color: var(--yellow);">15. WITHHOLDING PAYMENT AND OUR RIGHT TO SET OFF LIABILITY</h2>
        <p>We reserve the right to withhold payment and to declare bets on an event void if we have evidence that the
          following has occurred:</p>
        <ul>
          <li>the integrity of the event has been called into question</li>
          <li>the price(s) or pool has been manipulated</li>
          <li>match rigging has taken place</li>
        </ul>
        <p>Evidence of the above may be based on the size, volume or pattern of bets placed with us across any or all of
          our betting channels. A decision given by the relevant governing body of the sport in question (if any) will
          be conclusive. If any customer owes any money to qwerty for any reason, we have the right to take that
          into
          account before making any payments to that customer.</p>

        <h2 style="color: var(--yellow);">16. 90-MINUTE FOOTBALL BETS</h2>
        <p>What is meant by '90-minute Betting', 'Full Time' and 'Normal Time'? Adult football matches are played over a
          90-minute period, and this period can be called '90-minutes', 'Full Time' or 'Normal Time'. All football bets,
          unless specifically stating they are for Outright, To Qualify, Extra Time or Penalties markets, will be
          settled on the result after Normal Time (90 minutes).</p>
        <p>During the game, the referee may stop the game for short periods for substitutions, or if a player gets
          injured, and at the end of each 45-minute half the referee adds however many minutes he feels is required to
          account for any of these stoppages. This additional time is called "Injury time". Full Time and Normal Time
          are terms for the full 90-minute period, plus any 'Injury Time' that the referee chooses to add. Full Time and
          Normal Time DO NOT include Extra Time.</p>
        <p>Some matches may have a FT period of eighty (80) minutes or LESS as per FIFA regulations. Such matches
          include; U/16, U/17, Women Seniors etc.</p>

        <h2 style="color: var(--yellow);">17. ABANDONED MATCHES</h2>
        <p>If a match is abandoned after it has commenced, all bets on that match will be made void except where
          settlement has already been determined. For example, where the first goal has been scored by a named player
          the First Goal scorer and Time of First Goal markets, amongst others, will stand.</p>

        <h2 style="color: var(--yellow);">18. POSTPONED/RE-ARRANGED MATCHES</h2>
        <p>A postponed match is void unless it is re-scheduled to commence within 24 hours of the original start time
          and this is confirmed within 12 hours of the original start time. In such circumstances where (a) void
          match(es) is/are included in an accumulator the bet will be settled on the remaining selections.</p>

        <h2 style="color: var(--yellow);">19. PRICES SUBJECT TO FLUCTUATION</h2>
        <p>All prices are subject to fluctuation up to the kick-off. All football bets will be settled using qwerty
          online (www.bahatibet.waliliana.co.ke ) prices at the time the bet is placed.</p>

        <h2 style="color: var(--yellow);">20. RELATED CONTINGENCIES IN ONE MATCH</h2>
        <p>Where we offer various betting opportunities from the same match (e.g. correct score, first player to score
          etc.) these cannot be combined in accumulative bets where the outcome is related (except where special fixed
          prices are available).</p>
        <p>Where an accumulative bet of this type has been accepted in error it will be settled by equally dividing the
          stake unit where the related outcomes clash.</p>

        <h2 style="color: var(--yellow);">21. RESULTS</h2>
        <p>If the result is not immediately available from an official channel, then the result published by the
          official governing body immediately the match/event is finished will be used for settling purposes. Any
          subsequent corrections or amendments to this result will not apply for settlement purposes.</p>
        <p>qwerty will settle all bets rapidly but because of time differences, for some events, bets may be
          settled
          overnight or with some delay.</p>

        <h2 style="color: var(--yellow);">22. MAXIMUM PAYOUTS</h2>
        <p>(a) Minimum Bet Amount: The minimum betting amount for a single/multi bet is Ksh10.</p>
        <p>(b) Maximum Bet Amount: The maximum betting amount for a single bet is Ksh 10,000.</p>
        <p>(c) Maximum Bet Amount: The maximum betting amount for a multi bet is Ksh 10,000.</p>
        <p>(d) Maximum Bet Amount: The maximum betting amount for a Jackpot bet is Ksh49.</p>
        <p>(e) Maximum Single Bet Winning: The Single bet winning amount is limited to Ksh 200,000.</p>
        <p>(f) Maximum Multi bet Winning: The Multi bet winning amount is limited to Ksh 1,000,000.</p>
        <p>
          (g) Maximum Aggregate Winning (maximum payout): The Maximum winnings amount per customer per day is limited
          to Ksh 1,500,000.
        </p>

        <h2 style="color: var(--yellow);">23. ACCOUNT CLOSURE/FREEZE</h2>
        <p>We reserve the right to close/freeze any customer account or refuse to take bets from any customer. In this
          event of account closure, the full value of any customer funds will be returned as soon as applicable</p>
        <p>Should you attempt to withdraw funds that were deposited but not used for wagering, the Company may levy a
          processing fee of 10% upon such withdrawals. Additionally, should these transactions be deemed suspicious, the
          Company will report the activity to the appropriate authority and the player may lose these funds.</p>

        <h2 style="color: var(--yellow);">24. BETTING PROCEDURES</h2>
        <p>Anyone above 18years of age can play as many times as they desire but within the daily limits on bet values
          and payouts set aside in our Terms and Conditions</p>
        <p><strong style="color: var(--yellow);">Registration:</strong> Simply SMS “qwerty” to 23511. You will receive a confirmation message
          from
          23511 confirming that you are now registered and ready to bet.</p>
        <p><strong style="color: var(--yellow);">qwerty Account Top-up M-PESA:</strong> To top up your qwerty Account, go to the Mpesa Menu,
          Lipa na
          Mpesa, go to pay bill number enter business number 575758, account no. *Your Phone Number* and enter the
          amount you wish to deposit. You can also deposit online at www.bahatibet.waliliana.co.ke. Once you’ve logged in, click on
          ‘Deposit’, enter amount and then click on ‘Top Up Now’. AIRTEL TOP-UP(not available at the moment)</p>
        <p><strong style="color: var(--yellow);">Balance:</strong> To check your balance, SMS the word BALANCE to 23511.</p>
        <p><strong style="color: var(--yellow);">Placing Bets:</strong> The minimum bet amount is Ksh 10 and the maximum limit is set in the terms and
          conditions.</p>
        <!-- More detailed procedure for placing bets -->
        <p><strong style="color: var(--yellow);">Types of bets:</strong></p>
        <ul>
          <li>Single Bet - Place bet in this format: GAMEID#PICK#AMOUNT</li>
          <li>Multi Bet - Place bet in this format: GAMEID#PICK#AMOUNT</li>
          <li>Halftime/Fulltime - Place bet in this format: GAMEID#HtFt(Halftime Pick Fulltime Pick)#AMOUNT</li>
          <li>Correct Score - Place bet in this format: Game ID#ftcspick#Amount</li>
          <li>Draw No Bet - Place bet in this format: GAMEID#PICKAMOUNT</li>
          <li>First Half - Place bet in this format: GAMEID#PICKAMOUNT</li>
          <li>First Half - Both Teams to Score - Place bet in this format: GAMEID#PICKAMOUNT</li>
        </ul>
        <p><strong style="color: var(--yellow);">Winners' Notification:</strong> Winners will receive an SMS notification of their winnings and have
          it deposited in their qwerty account within 24 hours after the game ends. The extra time allows us to
          validate the results and post them on our website www.qwerty.com</p>
        <p><strong style="color: var(--yellow);">PROMOTIONAL SMS:</strong> To stop receiving promotional messages from qwerty, SMS the word
          'STOP' to
          23511</p>
        <p><strong style="color: var(--yellow);">Withdraw:</strong> To withdraw, send WITHDRAW#AMOUNT to 23511. You can also withdraw on
          www.bahatibet.waliliana.co.ke. Once you are logged in, click on withdraw, enter amount and click on 'withdraw now'.
          Minimum withdrawable amount is Kshs. 100. Maximum withdrawable amount per day is Kshs. 300,000. Kindly note
          you are not able to withdraw your bonus apart from the Jackpot bonus. Withdrawal charges shall apply.</p>

        <h2 style="color: var(--yellow);">25. Correct score bet on 5 matches</h2>
        <p>Players will be deemed to have accepted these terms and conditions and agreed to be bound by them when
          entering this competition.</p>
        <p>The bet amount is fixed at Ksh. 50.</p>
        <p>The qwerty5 Jackpot prize is fixed at Ksh. 2.5 million.</p>
        <p>The Jackpot is based on five (5) pre-selected football events which will kick off every Wednesday.</p>
        <p>These games will be available on <a href="www.bahatibet.waliliana.co.ke">www.bahatibet.waliliana.co.ke</a> and can also be accessed
          on SMS by sending qwerty5 to 23511.</p>
        <p>To place a bet on SMS, send <br> qwerty5#score1#score2#score3#score4#score5 to 23511. E.g. <br>
          qwerty5#2-0#1-1#4-3#0-0#3-1 to 23511.</p>
        <p>At the end of the last match in the Jackpot list, once the correct score outcomes of all the 5 matches have
          been acquired, the Jackpot winner(s) will be the ones who have predicted 5/5.</p>
        <p>Jackpot Bonus will be available for 4/5 winner(s).</p>
        <p>Jackpot bets are accepted five (5) minutes before the scheduled kick-off of the first football match of the
          Jackpot.</p>
        <p>The Jackpot amount is to be divided equally amongst all the Jackpot winners Where one or more qwerty5
          jackpot games are cancelled, we will void all bets placed and provide an alternative match list of 5 games to
          replace the current one within 24hrs from the time of cancellation.</p>
        <p>If a match is abandoned or postponed from its scheduled date/time but is then rescheduled to take place no
          later than the advertised kick-off time of the last match on the list, then provided the match is played to
          completion at the re-arranged time, all selections will stand.</p>
        <p>All correct score selections should be confirmed before placing a bet, as once submitted, qwerty5 bets
          cannot be cancelled, amended or refunded.</p>
        <p>The official results of the qwerty Jackpot will be announced on the website <a
            href="www.bahatibet.waliliana.co.ke">www.bahatibet.waliliana.co.ke</a>
          and all available Social Media platforms. You can also get results on SMS by sending qwerty5#RESULTS to
          23511.</p>

        <h2 style="color: var(--yellow);">26. Milioni Mbili Jackpot</h2>
        <p>The qwerty Jackpot Competition consists of predicting the results of 10 matches, which are selected by
          qwerty, every day.</p>
        <p>To take part and have a chance to win the jackpot, you must get registered and have at least KES 10 in your
          account. If you correctly predict all 10 match results, you win the Jackpot prize!</p>
        <h2 style="color: var(--yellow);">How to Take Part</h2>
        <p>Make your selections – On the qwerty Jackpot competition entry page, make your predictions for each one
          of
          the listed matches (Home win, Draw, Away win). Stake amount of each combination is KES 20.</p>
        <p>The Jackpot prize is fixed at Kshs.2,000,000 to be shared among all jackpot winner(s).</p>
        <p>At the end of the last match in the Jackpot list, once the correct outcomes of all the 10 matches have been
          acquired, the Jackpot winner(s) will be the ones who have predicted 10/10 games correctly.</p>
        <p>The jackpot bonus prize will be awarded to players who correctly predict 9/10 games of the Jackpot.</p>
        <p>The Jackpot will only have a 3-way market (1X2 Predictions).</p>
        <p>All Jackpot bet selections should be confirmed before placing a bet, as once submitted, jackpot bets cannot
          be canceled or amended.</p>
        <p>Jackpot bets will be accepted just before the scheduled kick-off of the first football match of the Jackpot.
          Any entries after will be declared void with stakes refunded.</p>
        <p>Where one Jackpot game is canceled, interrupted, abandoned, suspended, or postponed, the prize will be
          divided by 3.</p>
        <p>Where two Jackpot games are canceled, interrupted, abandoned, suspended, or postponed, the prize will be
          divided by 9.</p>
        <p>Where more than three (3) games are canceled, interrupted, abandoned, suspended, or postponed, qwerty
          will
          cancel the particular Jackpot event and refund the stake placed.</p>
        <p>To receive the Jackpot games via SMS, a player shall send the word "JP games" to 23511.</p>
        <p>The Jackpot games shall also be listed on bahatibet.waliliana.co.ke and there will be 2 Jackpot options: Auto Jackpot
          pick - Where the system randomly makes selections for the player, Own Selection – Where the customer makes his
          own jackpot selection.</p>
        <p>The Jackpot prize will be paid out in full within 72 hours after the Jackpot has been settled.</p>
        <p>Confirmation SMS shall be sent for all correctly placed Jackpot bets.</p>
        <p>The Jackpot Results shall be posted on the qwerty website immediately after the full results have been
          verified.</p>
        <p>The Jackpot prize will be paid out in full within 72 hours after the Jackpot matches have been settled.</p>
        <p>The Jackpot Bonus prizes will be paid by 4:00 pm the day after the Jackpot has been settled. The Exception
          being the Jackpots that take place between Friday and Sunday, the prizes will be credited by 4:00 pm on
          Monday.</p>
        <p>All prizes above Ksh.500,000 shall be paid via Bank transfer or Cheque and the winner(s) shall be required to
          avail themselves to qwerty offices with proof of identity before any such payment is made. qwerty
          reserves
          the right to verify, with the relevant authorities, any identification document presented, before making any
          payment.</p>
        <p>All winnings are subject to a 20% tax on the win as a government requirement.</p>

        <h2 style="color: var(--yellow);">27. Check and place bets</h2>
        <p>Make sure to check all the selections before you click on the "Place Bet" button. Once submitted, the bets
          cannot be canceled, amended, or refunded. You may place bets until 10 minutes before Kickoff of the first
          match. The countdown to the competition’s closure time is clearly shown on the competition entry page.</p>

        <h2 style="color: var(--yellow);">28. Jackpot Auto Pick</h2>
        <p>By choosing the “Jackpot auto pick” option, a random selection of one prediction per match will be
          automatically selected with a fixed stake of KES 20.</p>

        <h2 style="color: var(--yellow);">29. ALTERATION OF THE WEBSITE</h2>
        <p>qwerty may, in our absolute discretion, alter or amend any product or service (including any prices
          offered)
          available through the website (www.bahatibet.waliliana.co.ke) at any time for the purpose of ensuring the ongoing
          provision of the website, but without prejudice to any games and/or bets already in progress at the time of
          such amendment. From time to time, we may restrict you from accessing some parts of the website for the
          purpose of maintenance of the website and/or alteration or amendment of any of the games and/or products
          available through the website.</p>
        <!-- Section: MAXIMUM STAKE -->
        <h2 style="color: var(--yellow);">30. MAXIMUM STAKE</h2>
        <p>qwerty allows a maximum stake of Ksh 500,000 per bet for all single and multi bets. The validity of bets
          will be decided at the discretion of qwerty.</p>
        <!-- Section: CASHBACK -->
        <h2 style="color: var(--yellow);">31. CASHBACK</h2>
        <p>Award 50% of stake after tax if a player’s bet meets the following conditions:
        <ul>
          <li>Min stake 50</li>
          <li>Min games 5</li>
          <li>Min and Max lost games 1</li>
          <li>Award type; free bet</li>
          <li>Only applicable on sportsbook</li>
          <li>Not applicable to bonus bets</li>
        </ul>
        In the event a player’s bet is voided.
        <ul>
          <li>Award cashback if the above conditions are still met, e.g., a multibet of 6 games staked with KES 50 and
            consisting of one void selection, 4 wins and a single lost event still qualifies for cashback.
          </li>
        </ul>
        </p>
        <!-- Section: FREE BET -->
        <h2 style="color: var(--yellow);">32. FREE BET</h2>
        <p>We offer a Free bet worth KSHS 50/= to all new customers.
        <ul>
          <li>Select Home team win (1), Draw (X) or Away team win (2), from day’s free bet game.</li>
          <li>Enter your phone number *****</li>
          <li>Choose your account password (4 or more characters). Select ‘Submit free bet’.</li>
          <li>Your qwerty account will be created and your Freebet will be placed. Please don't forget to verify
            your
            account once you receive our SMS code; unverified accounts will lose their Freebet after 7 days. To view
            your bet, log on to qwerty.com, go to ‘Menu’ and choose ‘My Bets’.
          </li>
          <li>Freebet offer is limited to one per customer and cannot be used in conjunction with any other offer.
          </li>
          <li>When your Freebet wins, qwerty keeps its stake and pays the winnings into your qwerty account.
            You can
            use these winnings to bet on more matches.
          </li>
        </ul>
        Abuse: Persons attempting to take unfair advantage of the Freebet offer by creating multiple new accounts,
        inputting invalid phone numbers, or depositing funds to reach the withdrawal threshold risk having all
        associated accounts closed and all funds confiscated.
        </p>
        <!-- Section: VOID BET -->
        <h2 style="color: var(--yellow);">33. VOID BET</h2>
        <p>If an employee makes an error while accepting a bet (obvious misprints on the list of events, inconsistency
          of odds between offered betting markets and the bet, etc), or a bet is accepted in violation of these Rules,
          or if there are any other indications that the bet is wrong, the bookmaker reserves the right to declare such
          bets void. Returns on such bets shall be paid at odds of 1.</p>

        <h2 style="color: var(--yellow);">34. Suspected Unsportsmanlike Behavior</h2>
        <p>In the event of suspicions in the unsportsmanlike format of matches, the company reserves the right to block
          bets on sport events before the final conclusion of an international organization and declare bets as invalid
          if the fact of an unsportsmanlike game is determined. Payment of these bets is made with odds "1". The
          administration is not obliged to present evidence and conclusions to the customers.</p>
        <!-- Section: 34 -->
        <h2 style="color: var(--yellow);">35. Account Security</h2>
        <p>The Customer shall be responsible for keeping their password and account number received at registration
          confidential. All bets registered with the bookmaker shall be valid. Bet cancellation shall be subject to
          these Rules. Should the Customer’s login details come into the possession of a third party, the bookmaker
          should be informed, the Customer should change their username and password and their email password to
          stronger ones. You must not disclose any cash withdrawal codes or codes for changing your phone number to any
          third party.</p>
        <!-- Section: 35 -->
        <h2 style="color: var(--yellow);">36. Settlement of Bets</h2>
        <p>Bets shall be settled and winnings shall be determined based only on the results declared by the bookmaker.
          Any complaints about the results, date, and actual starting time of the event shall be considered together
          with official documents from the relevant sports federations.</p>
        <!-- Trademarks -->
        <p>qwerty, qwerty.com and the logo/mark above are trademarks and properties of qwerty Limited.
        </p>


      </div>


      <!--      <div class="terms-section p-2 text-light text-justify">
              <strong style="color: var(--yellow);"> qwerty TERMS & CONDITIONS </strong>
              <br><br>
              These general Terms and Conditions shall be valid for all betting games offeres by qwerty. qwerty is a trademark registered and owned by qwerty Limited, company registered under Kenyan Laws and hereinafter the name qwerty shall synonymously be used with qwerty.
              <br><br>
              By using our SMS and/or visiting any section of the Website, or by opening an account with qwerty through the Website, or SMS, you agree to be bound by the Terms and Conditions. qwerty reserves the right to change these Terms and Conditions at any time and without prior notice and will endeavor to inform you about major modifications to the Terms and Conditions. We may need to change the Terms of Use from time to time for a number of reasons, including (without limitation) for commercial reasons, to comply with law or regulations, to comply with instructions, guidance or recommendations from a regulatory body, or for customer service reasons.
              <br><br>
              The user is responsible in ensuring that they don’t infringe on any laws in their jurisdiction when opening an account and conducting business with qwerty at all times during the subsistence of their subscription/ participation.
              <br><br>
              qwerty will accept no liability from third parties whatsoever, resulting from you providing incorrect or false data.
              <br><br>
              One customer may open only one account. Should we identify any customer with more than one account, we reserve the right to close these accounts. If qwerty detects multiple accounts which have been setup deliberately with misleading information, have displayed criminal behavior, or if qwerty concludes that the account-owner has fraudulent intentions, qwerty shall be entitled to close the accounts and confiscate all funds, including initial deposit.
              <br><br>
              Notwithstanding anything contained in these Terms and Conditions, the Customer's winnings shall be subject to the governing regulations in the Republic of Kenya and this shall include but not be limited to applicab taxes and levies.
              <br><br>
              <strong style="color: var(--yellow);">DEFINITIONS</strong>
              <br>
              In these rules the following words have the following meanings unless the context requires otherwise.
              <br><br>
              Bet means the staking of money on the prediction of the outcome of a sporting event offered by us.
              <br>
              A bet can include one or more predictions. A bet on one outcome is a single.<br> A bet on many different outcomes is a multiple.
              <br><br>
              Odds or Price means the return on the money staked should the prediction recorded in a Bet be correct. A winning price of 2 would return double to the stake to the customer.
              <br><br>
              Customer means anyone placing a bet with qwerty or any other services hosted or sponsored by said company.
              <br><br>
              User means anyone using this service
              <br><br>
              Full time (FT) means the point when the official 90 minutes of a full match has elapsed plus any 'Injury Time' that the referee chooses to add and shall NOT include scheduled extra time (two half times of 15 minutes each), penalty shoot-outs etc.
              <br><br>
              VOID means that for one reason or another the bet is not acceptable to qwerty. In these cases, this bet is settled at the price of 1. In the case of a single bet this would mean the stake is returned to the customer.
              <br><br>
              <strong style="color: var(--yellow);">1. IDENTITY OF CUSTOMERS</strong>
              <br><br>
              Your mobile phone number is your identity. The identity of customers is determined by mobile phone number. We strongly encourage all customers to sign up for identity programs administered by the mobile phone companies. We use mobile phone numbers to uniquely identify customers and their account details. If transactions are made via your phone by someone who is not the registered user, the registered user will remain liable for their transactions. In addition, we cannot do account transactions for accounts which are not identical to the phone number. This means that you cannot, for example, ask to be paid out on a different number other than the number you have placed a bet with; also you cannot send money to us and request that this is placed in a different account. In addition to your phone number your initial transaction will initiate the creation of a virtual account to which all monies added to the cause of betting will be held.
              <br><br>
              Note: This is not a standard banking account and it shall not be used to keep money other than the use to place bets.
              <br><br>
              <strong style="color: var(--yellow);">2. AGE RESTRICTION</strong>
              <br><br>
              The Law prohibits under 18 year olds from betting. We may at any time ask you to confirm your age. You indemnify us of any damages if you deceive us or misrepresent your age. Underage clients will have their accounts closed immediately and any balances forfeit. We may also suspend ANY account for any reason and ask you to come with ID to one of our offices or agents to prove age, and identity. Individuals suspecting underage gambling are encouraged to report this to us immediately on our customer service number or via email to support@bahatibet.waliliana.co.ke
              <br><br>
              <strong style="color: var(--yellow);">3. ACCOUNT SECURITY</strong>
              <br><br>
              Customers are the registered user of their mobile number and Account. Customers are responsible for the security of their phone numbers, Account details and their mobile money accounts. We strongly recommend that customers do not disclose any security sensitive information to any other person. In the event that an unauthorized person uses a customer's phone for betting or money transactions to us, the registered user remains responsible.
              <br><br>
              We may also ask you to identify yourself and collect your winnings at one of our premises if we so require.
              <br><br>
              <strong style="color: var(--yellow);">4. PRICING</strong>
              <br><br>
              Customers will incur charges when using SMS services. We keep these as low as possible, currently at Ksh.3 for each SMS you receive from us on any of the participating networks. The minimum bet amount is Kshs. 10 for single bets and multibets. Jackpot betting is at a fixed stake of Kshs. 50. The minimum bet amount and fixed stake for jackpot may change from time to time and customers shall bear the responsibility to confirm and update themselves with the said changes.
              <br><br>
              <strong style="color: var(--yellow);">5. ACCEPTANCE AND VALIDATION OF BETS</strong>
              <br><br>
              All football bets will be settled on 90 minutes’ play (also referred to as Full-time and normal time). This denotes the period of play which includes time added by the Referee for injuries and other stoppages. This does not include scheduled extra time, or Penalty shoot-outs, if played.
              <br><br>
              In matches where penalty shoot outs or extra time are due to take place, all bets are settled on 90 minutes unless an Outright price is specifically requested and confirmed at the time the bet is placed.
              <br><br>
              Where qwerty have not quoted prices on a match, any single bet on that match will be void and treated as a non-runner in multiple bets.
              <br><br>
              If any team starts a 90-minute competitive game with less than 11 players, all bets on that match will be made void.
              <br><br>
              <strong style="color: var(--yellow);">6. LATE BETS</strong>
              <br><br>
              Bets can only be placed on the outcome of a future event, unless we are offering special 'betting in-running prices' for that event. If qwerty mistakenly accepts a bet for an event that has already started then the bet will be void and your stake returned, except in the following circumstances: - Where bets are taken after the start of an event, bets will stand if nothing has happened which would significantly alter the chances of the selection winning or losing.
              <br><br>
              All bets placed after the result of an event is known will be void win or lose.
              Internet bets include bets placed on any of our remote channels including our www.bahatibet.waliliana.co.ke website and via SMS. In the case of such bets, the bet is struck when a bet id or receipt exists in our back end systems or database. We may refuse to take bets placed if they are deemed wrong or mistaken.
              <br><br>
              <strong style="color: var(--yellow);">7. BET CANCELLATION</strong>
              <br><br>
              You can cancel a bet ten(10) minutes after placing that particular bet. This should be before the kick-off time of a match(es) selected in that bet. The maximum number of bets that can be cancelled in a day is three(3).
              <br><br>
              To cancel a bet on SMS, send CANCEL#BET ID to 23511. You can also cancel a bet by logging into the website, www.bahatibet.waliliana.co.ke, 'My Bets' then select the particular Bet ID you want to cancel, click on "Cancel Bet' and then confirm.
              <br><br>
              <strong style="color: var(--yellow);">8. ACCOUNT CORRECTIONS</strong>
              <br><br>
              qwerty has the right to recover from you any amount overpaid and has your authority to adjust your account to reflect the true outcome and rectify the mistake. An example of such a mistake might be where a price is incorrect, a bet is late (see Acceptance and Validation of Bets), where a maximum payout limit has been reached and paid out erroneously or where we enter a result of an event incorrectly.
              <br><br>
              <strong style="color: var(--yellow);">9. DISPUTES</strong>
              <br><br>
              If you have a complaint; you can email customer support on support@bahatibet.waliliana.co.ke and if your query or complaint is not resolved, you can ask for the matter to be escalated to the Head of Betting Operations. The Head of Betting Operations will investigate and contact you back with a resolution within 48 hours. You will be given the name and status of the people to whom your query/complaint has been referred. If you remain dissatisfied, we may agree with you to refer the matter to an independent third body for arbitration.
              <br><br>
              qwerty will at all times apply best efforts to resolve a reported matter promptly. If you have a query with regard to any transaction, you may also contact qwerty at support@bahatibet.waliliana.co.ke with details of the query. qwerty will review any queried or disputed transactions.
              <br><br>
              If for some reason you are not satisfied with the resolution of the complaint by the Company, you can complain to the Betting Control and Licensing Board ("BCLB"). Kindly note that by contacting the BCLB you are confirming that you have not breached any of the qwerty Terms and Conditions as agreed to by you upon registration to our service.
              <br><br>
              In all other times where a dispute arises, parties shall refer the matter for Arbitration by a single arbitrator agreed by the parities where the chosen venue shall be Nairobi. Where there is no agreement each party shall appoint an arbitrator and the nominated arbitrators shall appoint a single arbitrator and where they are unable to agree the Chairman for the time being of the Kenyan Chapter of Chartered Institute of Arbitrators shall appoint one.
              <br><br>
              Offensive or rude language, as well as malicious or damaging comments, will not be tolerated while contacting our staff or while discussing our products and services in any media, social network or forum. Any infringement of this policy will result in a suspension of the Account or in every additional action as may be required in order to ensure compliance.
              <br><br>
              <strong style="color: var(--yellow);">10. ERRORS</strong>
              <br><br>
              qwerty makes every effort to ensure that we do not make errors in accepting bets. However, if as a result of human error or system problems a bet is accepted at a price (which includes the odds, handicap provisions, and other terms and conditions or details of the bet) that is:
              materially different from those available in the general market at the time the bet was made; or clearly incorrect given the chance of the event occurring at the time the bet was made then qwerty will pay winnings at the correct price.
              <br><br>
              To establish the correct price qwerty will consider prices available in the general market at the time the bet was made, including the prices offered by other bookmakers operating in the same market.
              <br><br>
              Examples of circumstances where this would apply are:
              - the price is recorded as 100-1 when the price on offer in the general market is 10-1
              - the margins for handicap betting have been reversed
              <br><br>
              If a bet is accepted in error by qwerty on an event or outcome for which no qwerty prices are available, the bet will be void and your stake returned.
              <br><br>
              A bet accepted on the website or via SMS does not constitute a legally binding contract between the customer and us. The prices we offer on all outcomes on display on this website take the form of an advertisement rather than a contractual term. If we offer mistaken prices, then we are entitled to decline any bet placed at those prices at any time. In such instances we will void the bet and return the stake money.
              <br><br>
              Any results or scores displayed on the site for example during betting in play are for guidance purposes only.
              <br><br>
              <strong style="color: var(--yellow);">11. FRAUD</strong>
              <br><br>
              qwerty will seek criminal and contractual sanctions against any customer involved in fraud, dishonesty or criminal acts. qwerty will withhold payment (including but not limited to possible payouts and bet amount) to any customer where any of these are suspected.
              <br><br>
              The customer shall indemnify and shall be liable to pay to qwerty, on demand, all costs, charges or losses sustained or incurred by qwerty (including any direct, indirect or consequential losses, loss of profit and loss of reputation) arising directly or indirectly from the customer's fraud, dishonesty or criminal act.
              <br><br>
              <strong style="color: var(--yellow);">Match or Price Rigging:</strong>
              <br><br>
              qwerty takes sporting and betting integrity very seriously. We work with our Gaming Regulators and independent Sports Associations to help ensure that the fairness of gaming and the integrity of sport are protected.
              In the event that the company suspects any event manipulation it withholds the right, in its absolute discretion, to:
              <br><br>
              <ul>
                <li>suspend the offering of any event or series of events in any of its markets; and</li>
                <li>delay and/or withhold payment on any event or series of events in any of its markets, until the integrity of such event or series of events has been confirmed by the relevant sports federation.</li>
              </ul>
              Further, in the case of active event manipulation being confirmed as having taken place on any event or series of events by the appropriate sport’s governing bodies, the company withholds the right, in its absolute discretion, to suspend any bets placed on such events, either by any individual identified as having possessed insider betting knowledge or information or by any other individual who in the reasonable opinion of the company is connected to, acting in conjunction with or in any way involved with such individual.
              <br><br>
              If the company believes that match or price rigging has taken place, it reserves the right to relay the respective party's saved details (information pertaining to the reservations or suspicions of the respective misdemeanour in question) to sporting bodies, authorities or any other third party, which deals with the investigation of such offences.
              <br><br>
              <strong style="color: var(--yellow);">12. PRICE CHANGES</strong>
              <br><br>
              All prices are subject to change and may, on occasion, be restricted to certain stake levels. The prices offered via our different business channels may vary. For example, our betting shops may offer slightly different prices from those available on this website or available by SMS.
              <br><br>
              <strong style="color: var(--yellow);">13. RESULTS</strong>
              <br><br>
              In the case of Football and other Sports, bets are settled on the official result published by the relevant event managers or result handlers 3 to 24 hours after the match/event is finished.
              <br><br>
              <strong style="color: var(--yellow);">14. VOID BET</strong>
              <br><br>
              "Void Bet" means the bet is nil or invalid. This occurs when an event is postponed/cancelled, or when it has started but not finished within the period specified in our policy. If a game has been cancelled or postponed there is always a 24 hours wait until the match will be set as void. Once the match has been set as void (with odd 1.00) the rest of the winning ticket will then be paid out. If a selection in a single bet is made void the stake will be returned. Void selections in multiple bets will be treated as non-runners and the stake will run onto the remaining selections in the bet.
              <br><br>
              <strong style="color: var(--yellow);">15. WITHHOLDING PAYMENT AND OUR RIGHT TO SET OFF LIABILITY</strong>
              <br><br>
              We reserve the right to withhold payment and to declare bets on an event void if we have evidence that the following has occurred:
              <br><br>
                  • the integrity of the event has been called into question
                  • the price(s) or pool has been manipulated
                  • match rigging has taken place
              <br><br>
              Evidence of the above may be based on the size, volume or pattern of bets placed with us across any or all of our betting channels. A decision given by the relevant governing body of the sport in question (if any) will be conclusive. If any customer owes any money to qwerty for any reason, we have the right to take that into account before making any payments to that customer.
              <br><br>
              <strong style="color: var(--yellow);">16. 90-MINUTE FOOTBALL BETS</strong>
              <br><br>
              What is meant by '90-minute Betting', 'Full Time' and 'Normal Time'?
              Adult football matches are played over a 90-minute period, and this period can be called '90-minutes', 'Full Time' or 'Normal Time'. All football bets, unless specifically stating they are for Outright, To Qualify, Extra Time or Penalties markets, will be settled on the result after Normal Time (90 minutes).
              <br><br>
              During the game, the referee may stop the game for short periods for substitutions, or if a player gets injured, and at the end of each 45-minute half the referee adds however many minutes he feels is required to account for any of these stoppages. This additional time is called "Injury time". Full Time and Normal Time are terms for the full 90-minute period, plus any 'Injury Time' that the referee chooses to add. Full Time and Normal Time DO NOT include Extra Time.
              <br><br>
              Some matches may have a FT period of eighty (80) minutes or LESS as per FIFA regulations. Such matches include; U/16, U/17, Women Seniors etc.
              <br><br>
              <strong style="color: var(--yellow);">17. ABANDONED MATCHES</strong>
              <br><br>
              If a match is abandoned after it has commenced, all bets on that match will be made void except where settlement has already been determined. For example, where the first goal has been scored by a named player the First Goal scorer and Time of First Goal markets, amongst others, will stand.
              <br><br>
              <strong style="color: var(--yellow);">18. POSTPONED/RE-ARRANGED MATCHES</strong>
              <br><br>
              A postponed match is void unless it is re-scheduled to commence within 24 hours of the original start time and this is confirmed within 12 hours of the original start time. In such circumstances where (a) void match(es) is/are included in an accumulator the bet will be settled on the remaining selections.
              <br><br>
              <strong style="color: var(--yellow);">19. PRICES SUBJECT TO FLUCTUATION</strong>
              <br><br>
              All prices are subject to fluctuation up to the kick-off. All football bets will be settled using qwerty online (www.bahatibet.waliliana.co.ke ) prices at the time the bet is placed.
              <br><br>
              <strong style="color: var(--yellow);">20. RELATED CONTINGENCIES IN ONE MATCH</strong>
              <br><br>
              Where we offer various betting opportunities from the same match (e.g. correct score, first player to score etc.) these cannot be combined in accumulative bets where the outcome is related (except where special fixed prices are available).
              <br>
              Where an accumulative bet of this type has been accepted in error it will be settled by equally dividing the stake unit where the related outcomes clash.
              <br><br>
              <strong style="color: var(--yellow);">21. RESULTS</strong>
              <br><br>
              If the result is not immediately available from an official channel, then the result published by the official governing body immediately the match/event is finished will be used for settling purposes. Any subsequent corrections or amendments to this result will not apply for settlement purposes.
              <br>
              qwerty will settle all bets rapidly but because of time differences, for some events, bets may be settled overnight or with some delay.
              <br><br>
              <strong style="color: var(--yellow);">22. MAXIMUM PAYOUTS</strong>
              <br><br>
              (a) Minimum Bet Amount: The minimum betting amount for a single/multi bet is ksh20
              (b) Maximum Bet Amount: The maximum betting amount for a single bet is ksh 500,000.
              (c) Maximum Bet Amount: The maximum betting amount for a multi bet is ksh 500,000.
              (d) Maximum Bet Amount: The maximum betting amount for a Jackpot bet is ksh50 for weekly Jackpot and Ksh20 for Daily Jackpot.
              (e) Maximum Single Bet Winning: The Single bet winning amount is limited to ksh 1,000,000
              (f) Maximum Multi bet Winning: The Multi bet winning amount is limited to ksh 1,000,000
              (g) Maximum Aggregate Winning (maximum payout): The Maximum winnings amount per customer per day is limited to ksh 1,000,000
              <br><br>
              <strong style="color: var(--yellow);">23. ACCOUNT CLOSURE/FREEZE</strong>
              <br><br>
              We reserve the right to close/freeze any customer account or refuse to take bets from any customer. In this event of account closure, the full value of any customer funds will be returned as soon as applicable
              <br><br>
              Should you attempt to withdraw funds that were deposited but not used for wagering, the Company may levy a processing fee of 10% upon such withdrawals. Additionally, should these transactions be deemed suspicious, the Company will report the activity to the appropriate authority and the player may lose these funds.
              <br><br>
              <strong style="color: var(--yellow);">24. BETTING PROCEDURES</strong>
              <br><br>
              Anyone above 18years of age can play as many times as they desire but within the daily limits on bet values and payouts set aside in our Terms and Conditions
              <br>
              Registration: Simply SMS “qwerty” to 23511. You will receive a confirmation message from 23511 confirming that you are now registered and ready to bet.
              <br>
              qwerty Account Top-up M-PESA: To top up your qwerty Account, go to the Mpesa Menu, Lipa na Mpesa, go to pay bill number enter business number 575758, account no. *Your Phone Number* and enter the amount you wish to deposit. You can also deposit online at www.qwerty.com. Once you’ve logged in, click on ‘Deposit’, enter amount and then click on ‘Top Up Now’.
              AIRTEL TOP-UP(not available at the moment):
              <br><br>
              Balance: To check your balance, SMS the word BALANCE to 23511.
              <br><br>
              Placing Bets:
              <br>
              The minimum bet amount is Ksh 10 and the maximum limit is set in the terms and conditions.
              <br><br>
              There are three possible results in any football match:
              1 - Home Team wins
              X - Draw result
              2 - Away Team wins
              <br><br>
              To bet on your team, SMS qwerty to 23511 and you will receive current football match fixtures and their ODDS. To receive more games simply SMS qwerty to 23511. The more you SMS the more games you receive.
              <br>
              You can also visit the qwerty website www.bahatibet.waliliana.co.ke and identify the game you wish to bet on.
              <br><br>
              Types of bets:
              <br><br>
              Single Bet - Place bet in this format: GAMEID#PICK#AMOUNT
              <br>
              E.g. 123#ov25#200 123#un25#200
              <br>
              GAMEID 123 Arsenal vs Liverpool ODDS (1=2.54 X=3.47 2=3.10)
              <br>
              GAMEID 456 Manchester United vs Chelsea ODDS (1=2.87 X=4.13 2=3.90)
              <br><br>
              Multi Bet - Place bet in this format: GAMEID#PICK#AMOUNT
              <br>
              E.g. 123#ov25#456#un25#200
              <br><br>
              Halftime/Fulltime
              <br>
              GAMEID#HtFt(Halftime Pick Fulltime Pick)#AMOUNT
              <br>
              GAMEID 123 Arsenal vs Liverpool ODDS (1=2.54 X=3.47 2=3.10)
              <br>
              123#HtFtX1#100
              <br><br>
              Correct Score
              <br>
              Game ID#ftcspick#Amount e.g. 3214#ftcs23#100
              <br><br>
              Draw No Bet
              <br>
              Picks: DNB1/DNB2
              <br>
              GAMEID#PICKAMOUNT
              <br>
              GAMEID 123 Arsenal vs Liverpool ODDS (1=2.54 X=3.47 2=3.10)
              <br>
              123#DNB1#100
              <br><br>
              First Half
              <br>
              Picks: HT1/HT2
              <br>
              GAMEID#PICKAMOUNT
              <br>
              GAMEID 123 Arsenal vs Liverpool ODDS (1=2.54 X=3.47 2=3.10)
              <br>
              123#HT1#100
              <br><br>
              First Half - Both Teams to Score
              <br>
              Picks: HTGG/HTNG
              <br>
              GAMEID#PICKAMOUNT
              <br>
              GAMEID 123 Arsenal vs Liverpool ODDS (1=2.54 X=3.47 2=3.10)
              <br>
              123#HTNG#100
              <br><br>
              Winners' Notification: Winners will receive an SMS notification of their winnings and have it deposited in their qwerty account within 24 hours after the game ends. The extra time allows us to validate the results and post them on our website www.bahatibet.waliliana.co.ke
              <br><br>
              <strong style="color: var(--yellow);">PROMOTIONAL SMS</strong>
              <br>
              To stop receiving promotional messages from qwerty, SMS the word 'STOP' to 23511
              <br><br>
              Withdraw: To withdraw, send WITHDRAW#AMOUNT to 23511. You can also withdraw on www.bahatibet.waliliana.co.ke. Once you are logged in, click on withdraw, enter amount and click on 'withdraw now'. Minimum withdrawable amount is Kshs. 100. Maximum withdrawable amount per day is Kshs. 300,000. Kindly note you are not able to withdraw your bonus apart from the Jackpot bonus. Withdrawal charges shall apply.
              <br><br>
              <strong style="color: var(--yellow);">25. (correct score bet on 5 matches) </strong>
              <br><br>
              Players will be deemed to have accepted these terms and conditions and agreed to be bound by them when entering this competition
              <br>
              The bet amount is fixed at Ksh. 50.
              <br>
              The qwerty5 Jackpot prize is fixed at Ksh. 2.5 million.
              <br>
              The Jackpot is based on five (5) pre-selected football events which will kick off every Wednesday.
              <br>
              These games will be available on www.bahatibet.waliliana.co.ke and can also be accessed on SMS by sending qwerty5 to 23511.
              <br><br>
              To place a bet on SMS, send
              <br>
              qwerty5#score1#score2#score3#score4#score5 to 23511. E.g.
              <br>
              qwerty5#2-0#1-1#4-3#0-0#3-1 to 23511.
              <br><br>
              At the end of the last match in the Jackpot list, once the correct score outcomes of all the 5 matches have been acquired, the Jackpot winner(s) will be the ones who have predicted 5/5.
              <br><br>
              Jackpot Bonus will be available for 4/5 winner(s).
              <br>
              Jackpot bets are accepted five (5) minutes before the scheduled kick-off of the first football match of the Jackpot.
              <br><br>
              The Jackpot amount is to be divided equally amongst all the Jackpot winners
      Where one or more qwerty5 jackpot games are cancelled, we will void all bets placed and provide an alternative match list of 5 games to replace the current one within 24hrs from the time of cancellation.
              <br><br>
              If a match is abandoned or postponed from its scheduled date/time but is then rescheduled to take place no later than the advertised kick-off time of the last match on the list, then provided the match is played to completion at the re-arranged time, all selections will stand.
              <br><br>
              All correct score selections should be confirmed before placing a bet, as once submitted, qwerty5 bets cannot be cancelled, amended or refunded.
              <br><br>
              The official results of the qwerty Jackpot will be announced on the website www.bahatibet.waliliana.co.ke and all available Social Media platforms. You can also get results on SMS by sending qwerty5#RESULTS to 23511.
              <br><br>





              <strong style="color: var(--yellow);">26. Milioni Mbili Jackpot</strong> <br><br>
              The qwerty Jackpot Competition consists of predicting the results of 10 matches, which are selected by qwerty, every day. <br><br>
              To take part and have a chance to win the jackpot, you must get registered and have at least KES 10 in your account. If you correctly predict all 10 match results, you win the Jackpot prize! <br><br>
              <strong style="color: var(--yellow);">How to Take Part</strong><br><br>
              Make your selections – On the qwerty Jackpot competition entry page, make your predictions for each one of the listed matches (Home win, Draw, Away win). Stake amount of each combination is KES 20. <br><br>
              The Jackpot prize is fixed at Kshs.2,000 000 to be shared among all jackpot winner(s).<br><br>
              At the end of the last match in the Jackpot list, once the correct outcomes of all the 10 matches have been acquired, the Jackpot winner(s) will be the ones who have predicted 10/10 games correctly. <br><br>
              The jackpot bonus prize will be awarded to players who correctly predict 9/10, games of the Jackpot.<br><br>
              The Jackpot will only have a 3-way market (1X2 Predictions)<br><br>
              All Jackpot bet selections should be confirmed before placing a bet, as once submitted, jackpot bets cannot be canceled or amended.<br><br>
              Jackpot bets will be accepted just before the scheduled kick-off of the first football match of the Jackpot. Any entries after will be declared void with stakes refunded.<br><br>
              Where one Jackpot game is canceled, interrupted, abandoned, suspended or postponed, the prize will be divided by 3. <br><br>
              Where two Jackpot games are canceled, interrupted, abandoned, suspended or postponed, the prize will be divided by 9.<br><br>
              Where more than three (3) games are canceled, interrupted, abandoned, suspended or postponed, qwerty will cancel the particular Jackpot event and refund the stake placed. <br><br>
              To receive the Jackpot games via SMS, a player shall send the word "JP games" to 23511<br><br>
              The Jackpot games shall also be listed on qwerty.com and there will be 2 Jackpot options:<br><br>
              Auto Jackpot pick - Where the system randomly makes selections for the player<br><br>
              Own Selection – Where the customer makes his own jackpot selection<br><br>
              The Jackpot prize will be paid out in full within 72 hours after the Jackpot has been settled.<br><br>
              Confirmation SMS shall be sent for all correctly placed Jackpot bets.<br><br>
              The Jackpot Results shall be posted on the qwerty website immediately after the full results have been verified.<br><br>
              The Jackpot prize will be paid out in full within 72hours after the Jackpot matches have been settled.<br><br>
              The Jackpot Bonus prizes will be paid by 4:00pm the day after the Jackpot has been settled. The Exception being the Jackpots that take place between Friday and Sunday, the prizes will be credited by 4:00pm on Monday.<br><br>
              All prizes above Ksh.500,000 shall be paid via Bank transfer or Cheque and the winner(s) shall be required to avail themselves to qwerty offices with proof of identity before any such payment is made. qwerty reserves the right to verify, with the relevant authorities, any identification document presented, before making any payment.<br><br>
              All winnings are subject to a 20% tax on the win as a government requirement.<br><br>

              Check and place bets – Make sure to check all the selections before you click on the "Place Bet" button. Once submitted, the bets cannot be cancelled, amended, or refunded. You may place bets until 10 minutes before Kickoff of the first match. The countdown to the competition’s closure time is clearly shown on the competition entry page.<br><br>

              Jackpot Auto Pick<br><br>
              By choosing the “Jackpot auto pick” option, a random selection of one prediction per match will be automatically selected with a fixed stake of KES 20.<br><br>
              &lt;!&ndash; New JP &ndash;&gt;
              <strong style="color: var(--yellow);">15M weekly Jackpot terms and conditions</strong> <br><br>
              The weekly jackpot is based on 13 football games<br><br>
              The bet is a winner if all the 13 predictions are correct<br><br>
              The entry for the weekly jackpot is Ksh. 50<br><br>
              The jackpot amount will be shared equally among all the jackpot winners if there is more than one winner.<br><br>
              Bonus for players with 12 correct prediction, the amount determined by qwerty at our own discretion.<br><br>
              Entries are accepted up to immediately 5min before kick-off of the first match of the jackpot. Any entries after will be declared void with stakes refunded.<br><br>
              Jackpots bets cannot be cancelled or amended once placed.<br><br>
              Where one, two or three Jackpot game is cancelled, interrupted, abandoned, suspended, or postponed, an official draw will be done to determine the results of the affected teams.<br><br>
              Where more than three (3) games are cancelled, interrupted, abandoned, suspended, or postponed, qwerty will cancel the Jackpot event and refund the stake placed.<br><br>
              Bonus money of free bet cannot be used to place Jackpot bets.<br><br>
              The result of all matches are settled on the result at the end of the normal time (90min play plus any allocated injury time). Extra time and penalty shootout are not included.<br><br>
              The official results will be posted on qwerty social media platforms.<br><br>
              qwerty reserves the right to verify the identity of the winner before final payment are made. The winners might also be required to avail themselves at our offices before payment is made.<br><br>
              qwerty reserves the right to use the names, video, audio, radio or other recording and images of winners for purposes of publicity and marketing.<br><br>
              All jackpot winnings and subsequent jackpot bonuses are subject to withholding tax of 20%<br><br>
      &lt;!&ndash; New JP &ndash;&gt;
              <strong style="color: var(--yellow);">28. ALTERATION OF THE WEBSITE</strong>
              <br><br>
              qwerty may, in our absolute discretion, alter or amend any product or service (including any prices offered) available through the website (www.bahatibet.waliliana.co.ke) at any time for the purpose of ensuring the ongoing provision of the website, but without prejudice to any games and/or bets already in progress at the time of such amendment. From time to time, we may restrict you from accessing some parts of the website for the purpose of maintenance of the website and/or alteration or amendment of any of the games and/or products available through the website.
              <br><br>
              <strong style="color: var(--yellow);">29. MAXIMUM STAKE</strong>
              <br><br>
              qwerty allows a maximum stake of Ksh 500,000 per bet for all single and multi bets. The validity of bets will be decided at the discretion of qwerty.
              <br><br>

              <strong style="color: var(--yellow);">30.	CASHBACK </strong><br>
              Award 50% of stake after tax if a player’s bet meets the following conditions: <br>
              ❖ Min stake 50 <br>
              ❖ Min games 5<br>
              ❖ Min and Max lost games 1 <br>
              ❖ Award type; free bet <br>
              ❖ Only applicable on sportsbook<br>
              ❖ Not applicable to bonus bets <br>
              In the event a player’s bet is voided. <br>
              ❖ Award cashback if the above conditions are still met, e.g., a multibet of 6 games staked with KES 50 and consisting of one void selection, 4 wins and a single lost event still qualifies for cashback.<br><br>

              <strong style="color: var(--yellow);">31. FREE BET</strong><br>
      We offer a Free bet worth KSHS 50/= to all new customers.<br>
      It’s easy to make your free bet:<br>
      Select <br>
      Home team win (1), Draw (X) or Away team win (2), from day’s free bet game.<br>
      Enter your phone number *****<br>
      Choose your account password (4 or more characters). Select ‘Submit free bet’.<br>
      Your qwerty account will be created and your Freebet will be placed. Please don't forget to verify your account once you receive our SMS code; unverified accounts will lose their Freebet after 7 days. To view your bet, log on to bahatibet.waliliana.co.ke, go to ‘Menu’ and choose ‘My Bets’.<br>
      Freebet offer is limited to one per customer and cannot be used in conjunction with any other offer.<br>
      When your Freebet wins, qwerty keeps its stake and pays the winnings into your qwerty account. You can use these winnings to bet on more matches.<br>
      Abuse: Persons attempting to take unfair advantage of the Freebet offer by creating multiple new accounts, inputting invalid phone numbers, or depositing funds to reach the withdrawal threshold risk having all associated accounts closed and all funds confiscated.<br><br>


      32. If an employee makes an error while accepting a bet (obvious misprints on the list of events, inconsistency of odds between offered betting markets and the bet, etc), or a bet is accepted in violation of these Rules, or if there are any other indications that the bet is wrong, the bookmaker reserves the right to declare such bets void. Returns on such bets shall be paid at odds of 1. <br><br>

      33. In the event of suspicions in the unsportsmanlike format of matches the company reserves the right to block bets on sport event before final conclusion of an international organization and declare bets as invalid if the fact of an unsportsmanlike game is determined. Payment of these bets is made with odds "1". The administration is not obliged to present evidence and conclusions to the customers.<br><br>

      34. The Customer shall be responsible for keeping their password and account number received at registration confidential. All bets registered with the bookmaker shall be valid. Bet cancellation shall be subject to these Rules. Should the Customer’s login details come into the possession of a third party, the bookmaker should be informed, the Customer should change their username and password and their email password to stronger ones. You must not disclose any cash withdrawal codes or codes for changing your phone number to any third party.<br><br>

      35. Bets shall be settled and winnings shall be determined based only on the results declared by the bookmaker. Any complaints about the results, date, and actual starting time of the event shall be considered together with official documents from the relevant sports federations.<br><br>

      36. The account holder confirms that any activities on the account are performed by themselves. If the account is managed by a third party, the account holder shall take exclusive responsibility for access to the account.<br><br>
              &lt;!&ndash; End &ndash;&gt;
              qwerty, qwerty.com and the logo/mark above are trademarks and properties of qwerty Limited. qwerty, bahatibet.waliliana.co.ke and the logo/mark above are trademarks and properties of qwerty Limited

            </div>-->
    </section>
  </div>

</template>

<script>

export default {
  name: 'Terms',
  components: {},
  mounted: function () {

    this.$store.dispatch("setCurrentPage", "terms");
    this.reloadProfile();

  }

}
</script>

<style scoped>
body {
  font-family: Arial, sans-serif;
  padding: 20px;
}

h1,
h2 {
  margin-top: 30px;
  margin-bottom: 20px;
}

h2 {
  font-size: 1.5em;
}

p {
  margin-bottom: 10px;
}

.definition-list {
  margin-top: 20px;
}

.definition-list dt {
  font-weight: bold;
}

.definition-list dd {
  margin-bottom: 10px;
}
</style>